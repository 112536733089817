
import { useState, useEffect } from 'react'
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { show } from '../../store/modules/setupStore'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FaChevronRight } from "react-icons/fa6";
import {
  Emission,
  Home,
  MinePool,
  Swap,
  Person,
  Invite,
  Gift,
  NodeMint,
  Nft,
  Com
} from '../../assets/images/omniNew/setup'
import copyText from 'copy-to-clipboard'
import { useAccount } from 'wagmi'
import { message } from 'antd'
export default function Example () {
  const nav = useNavigate()
  const { address } = useAccount()
  const [open, setOpen] = useState(false)
  const setup = useSelector(state => state.setupStore.show)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const toogleHiddle = () => {

    dispatch(show())
  }
  useEffect(() => {
    setOpen(setup)
  }, [setup])
  return (
    <Dialog open={open} onClose={toogleHiddle} className="relative z-10">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-16">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:-translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col overflow-y-scroll bg-[#000000] text-white  py-6 shadow-xl">
                {/* <div className="px-4 sm:px-6">
                  <div className="flex items-start justify-between">
                    <DialogTitle className="text-base font-semibold text-gray-900">Panel title</DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={() => toogleHiddle}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="size-6" />
                      </button>
                    </div>
                  </div>
                </div> */}
                <div className='px-4'>
                  <div className='w-[278px] h-[127px] bg-gradient-to-r from-[#16CBE7] to-[#7AFF50] rounded-lg flex flex-col p-4 '>
                    <div className='flex justify-between text-[14.4px]'>
                      <div className='flex items-center space-x-2'>
                        <div><Gift /></div>
                        <div>{t('invited')}</div>
                      </div>
                      <div>{t('more')}</div>
                    </div>
                    <div className='mt-6 text-[12.3px]'>{t('referralLink')}</div>
                    <div className='flex space-x-2 mt-2'>
                      <div className='w-[172px] h-[35px] bg-white text-black rounded-lg leading-6   p-1 truncate'>
                        {/* {t('pleaseBindTheLeader')} */}
                        {window.location.origin + '/home?invitation_Code=' + address}
                      </div>
                      <div className='w-[65px] h-[35px] bg-transparent border rounded-lg flex justify-center items-center'
                        onClick={() => {
                          if (address) {
                            copyText(
                              window.location.origin + '/home?invitation_Code=' + address
                            )
                            message.success(t('theReplicationIsSuccessful'))
                          }
                        }}

                      >{t('copy')}</div>
                    </div>
                  </div>
                </div>

                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                  <ItemMenu imgCom={<Home />} text={t('home')} onClick={() => {
                    nav('/home')
                    toogleHiddle()
                  }}></ItemMenu>
                  <ItemMenu imgCom={<Swap />} text={t('swap')} onClick={() => {
                    nav('/trade')
                    toogleHiddle()
                  }}></ItemMenu>
                  <ItemMenu imgCom={<Emission />} text={t('launchPad')} onClick={() => {
                    nav('/launchPad')
                    toogleHiddle()
                  }}></ItemMenu>
                  <ItemMenu imgCom={<MinePool />} text={t('miningPools')} onClick={() => {
                    nav('/minePool')
                    toogleHiddle()
                  }}></ItemMenu>
                  <ItemMenu imgCom={<NodeMint />} text={t('nodeMinting')} onClick={() => {
                    nav('/myNode')
                    toogleHiddle()
                  }} ></ItemMenu>
                  <ItemMenu imgCom={<Invite />} text={t('inviteFriends')} onClick={() => {
                    nav('/inviteFriend')
                    toogleHiddle()
                  }}></ItemMenu>
                  <ItemMenu imgCom={<Nft />} text={t('nftCardGame')} onClick={() => {
                    nav('/cardGame')
                    toogleHiddle()
                  }}></ItemMenu>
                  <ItemMenu imgCom={<Com />} text={t('myCommunity')} onClick={() => {
                    nav('/myCom')
                    toogleHiddle()
                  }}></ItemMenu>
                  <ItemMenu imgCom={<Person />} text={t('onlineCustomerService')} onClick={() => {
                    nav('/chamber')
                    toogleHiddle()
                  }}></ItemMenu>
                </div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const ItemMenu = ({ text, imgCom, onClick }) => {
  return (
    <div className='flex justify-between items-center w-full py-3' onClick={onClick}>
      <div className='flex items-center space-x-2'>
        <div>{imgCom}</div>
        <div>{text}</div>
      </div>
      <div><FaChevronRight /></div>
    </div>
  )
}