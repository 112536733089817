import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'normalize.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import BaseRouter from './router/index.jsx';
import { Provider } from 'react-redux'
import store from './store'
import i18n from "i18next";
// import * as Sentry from '@sentry/react';
import { initReactI18next } from "react-i18next";
import en_US from "./locales/en-US.json";
import zh_CN from "./locales/zh-CN.json";
import zh_TW from "./locales/zh-TW.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";
import es from "./locales/es.json";
import pt from "./locales/pt.json";
import de from "./locales/de.json";
import fr from "./locales/fr.json";
import th from "./locales/th.json";
import pl from "./locales/pl.json"

import 'normalize.css'

import { WagmiProvider, createConfig, configureChains, http } from 'wagmi'
// import { } from 'viem'
import { injected } from 'wagmi/connectors'
import { createPublicClient } from 'viem'
import { bscTestnet, bsc } from 'wagmi/chains';

import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  tokenPocketWallet,
  // walletConnectWallet,
  metaMaskWallet,
  injectedWallet
} from '@rainbow-me/rainbowkit/wallets';

import '@rainbow-me/rainbowkit/styles.css';
import { NextUIProvider } from "@nextui-org/react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
  darkTheme,
  getDefaultConfig,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';

// const { chains, publicClient, webSocketPublicClient } = configureChains(
//   [bscTestnet],
//   [publicProvider()],
// )
// const { chains, publicClient, webSocketPublicClient } = configureChains(
//   [
//     bsc,
//     // bscTestnet
//   ],
//   [
//     // alchemyProvider({ apiKey: process.env.ALCHEMY_ID }),
//     publicProvider()
//   ]
// );
const config = createConfig({
  // autoConnect: true,
  chains: [
    bsc,
    // bscTestnet
  ],
  // connectors,
  connectors: [
    injected({
      shimDisconnect: true, // 启用断开连接功能
      flags: {
        isTokenPocket: true, // 支持 TokenPocket 检测
        isMetaMask: true
      }
    }),
    // walletConnect({
    //   projectId: 'your_project_id',
    //   showQrModal: true
    // })
  ],
  transports: {
    [bsc.id]: http(),
    // [bscTestnet.id]: http(),
  },
})
// const config = getDefaultConfig({
//   // autoConnect: true,
//   appName: 'omnisphere',
//   projectId: 'bc5ae4b0a9d73ac61eed542f5fe6ac55',
//   chains: [
//     bsc,
//     // bscTestnet
//   ],
//   transports: {
//     [bsc.id]: http(),
//     // [bscTestnet.id]: http(),
//   },
//   // ssr: true, // If your dApp uses server side rendering (SSR)
// });
// const connectors = connectorsForWallets(
//   [
//     {
//       groupName: '推荐钱包',
//       wallets: [
//         tokenPocketWallet,  // TokenPocket 专用连接器
//         metaMaskWallet,    // MetaMask 专用连接器
//         injectedWallet      // 其他注入式钱包的通用连接器
//       ]
//     },
//     // {
//     //   groupName: '其他钱包',
//     //   wallets: [
//     //     walletConnectWallet // WalletConnect作为备选连接方式
//     //   ]
//     // }
//   ],
//   {
//     appName: 'Your App Name',
//     projectId: 'YOUR_WALLET_CONNECT_PROJECT_ID'
//   }
// );
// const wagmiConfig = createConfig({
//   autoConnect: true,
//   connectors,
//   publicClient,
//   // publicClient: createPublicClient({
//   //   chain: bsc,
//   //   // chain: bscTestnet,
//   //   transport: http()
//   // }),
//   webSocketPublicClient
// })
// const config = createConfig({
//   autoConnect: true,
//   connectors: [
//     new InjectedConnector({ chains }),
//   ],
//   publicClient,
//   // webSocketPublicClient,
// })

i18n.use(initReactI18next).init({
  lng:
    localStorage.getItem("lang") == "en_US"
      ? "en_US"
      : (localStorage.getItem("lang") == "zh_CN"
        ? "zh_CN"
        : (localStorage.getItem("lang") == "pl"
          ? "pl"
          : (localStorage.getItem("lang") == "ja" ? "ja" : (localStorage.getItem("lang") == "ko" ? "ko" : (localStorage.getItem("lang") == "es" ? "es" : (localStorage.getItem("lang") == "pt" ? "pt" : (localStorage.getItem("lang") == "de" ? "de" : (localStorage.getItem("lang") == "fr" ? "fr" : (localStorage.getItem("lang") == "th" ? "th" : "zh_TW"))))))))),
  fallbackLng: "zh_CN",
  debug: true,
  resources: {
    en_US: {
      translation: en_US,
    },
    zh_CN: {
      translation: zh_CN,
    },
    zh_TW: {
      translation: zh_TW,
    },
    ja: {
      translation: ja,
    },
    ko: {
      translation: ko,
    },
    es: {
      translation: es,
    },
    pt: {
      translation: pt,
    },
    de: {
      translation: de,
    },
    fr: {
      translation: fr,
    },
    th: {
      translation: th,
    },
    pl: {
      translation: pl,
    }
  },
});

//sentry监控，由于sentry的本地化部署还不会，线上需要钱，所以暂时注释掉
// Sentry.init({
//   dsn: "https://150aef7e13d86159d7636fa1c26dd82d@o4505661282516992.ingest.sentry.io/4505662849941504",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiProvider
      config={config}
      reconnectOnMount={true}
    >
      <QueryClientProvider client={queryClient}>
        {/* <RainbowKitProvider theme={darkTheme()} locale={
          localStorage.getItem("lang") == "zh_CN" ? "zh-Hans" : localStorage.getItem("lang") == "zh_TW" ? "zh-Hant" : localStorage.getItem("lang") == "en_US" ? "en" : localStorage.getItem("lang")
        }> */}
        <Provider store={store}>

          <BaseRouter />

        </Provider>
        {/* </RainbowKitProvider> */}
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
export { store } //导出store